<template>
  <div>
    <div v-if="isSuccess" class="text-center success--text">
      {{ $t("messages.connect_e_market_account_success") }}
    </div>
    <div v-else-if="isError" class="text-center error--text">
      {{ $t("messages.system_error_try_again") }}
    </div>
    <div v-else class="text-center warning--text">
      {{ $t("messages.loading") }}
    </div>
  </div>
</template>

<script>
import { httpClient } from "@/libs/http";

export default {
  name: "OauthZalo",
  data: () => ({
    isSuccess: false,
    isError: false,
  }),
  mounted() {
    this.getAccessToken();
  },
  methods: {
    async getAccessToken() {
      const state = this.getUrlParameter("state");
      const code = this.getUrlParameter("code");
      const code_challenge = this.getUrlParameter("code_challenge");
      if (!state || !code || !code_challenge) {
        this.isError = true;
        return false;
      }

      const [skey, id, is_master] = state.split("_");
      if (is_master === "1") {
        console.log(skey, id);
        const urlParams = window.location.search;
        const targetDomain = `${process.env.VUE_APP_MASTER_URL}/zalo-oauth`;
        window.location.href = targetDomain + urlParams;
        return true;
      }

      try {
        await httpClient.post("/get-zalo-oa-token", {
          code,
          code_challenge,
          state,
        });
        this.isSuccess = true;
        this.$vToastify.success(
          this.$t("messages.connect_e_market_account_success")
        );
        setTimeout(() => {
          this.$router.push({ name: "Account" });
        }, 3000);
      } catch (e) {
        this.isError = true;
        const errMsg =
          (e.response &&
            e.response.data &&
            e.response.data.error &&
            e.response.data.error.message) ||
          null;
        this.$vToastify.error(errMsg);
      }
    },
  },
};
</script>

<style scoped></style>
